@import "../../../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../../helpers/index";

.chart-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-left: 15px;
}

.chart-switch input {
    display: none;
}

.slider-thumb {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $cararra;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.slider-thumb:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider-thumb {
    background-color: $tango;
}

input:focus+.slider-thumb {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider-thumb:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider-thumb:after {
    content: "OFF";
    color: #808191;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 70%;
    font-size: 10px;
    font-family: Inter, sans-serif;
}

input:checked+.slider-thumb:after {
    content: none;
}
