.sort-icon {
  svg {
    display: block;
    opacity: .5;
    transition: opacity .2s ease-in-out;

    &:first-child {
      margin-bottom: 2px;
    }

    &.is-active {
      opacity: 1;
    }
  }
}