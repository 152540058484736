@import "../node_modules/bootstrap/scss/bootstrap.scss";

html,
body,
#root,
.App {
    height: 100%;
    overflow: hidden;
}

.App {
    background-color: #f4f2f0;
}

a:hover {
  color: inherit;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: rgba(128, 129, 145, 0.5);
    border-radius: 15px;
}

