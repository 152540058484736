// typography

@mixin cera {
  font-family: "Cera Pro", sans-serif;
}

@mixin system-fontstack {
  font-family: -apple-system, BlinkMacSystemFont, "Cera Pro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// media queries

@mixin rw-min($width) {
  @media only screen and (min-width: $width+"px") {
    @content;
  }
}

@mixin rh-min($height) {
  @media only screen and (min-height: $height+"px") {
    @content;
  }
}

@mixin rw-max($width) {
  @media only screen and (max-width: $width+"px") {
    @content;
  }
}
@mixin rh-max($height) {
  @media only screen and (max-height: $height+"px") {
    @content;
  }
}

// position

@mixin coverdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
