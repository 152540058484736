@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../../helpers/index";

.el-table .cell,
.el-table th > div {
  -webkit-overflow-scrolling: touch;
}

.el_table {
  -webkit-overflow-scrolling: touch !important;
}

.dashboard-table {
  position: relative;

  .aladin {
    display: none;

    @include rw-max(700) {
      display: block;
    }
  }



  &--debt {
    &::before,
    &::after {
      height: 16px;
    }

    .el-table__fixed {
      bottom: 16px !important;
    }

    .el-table::before {
      height: calc(100% - 16px) !important;
    }

    .aladin {
      @include rw-max(700) {
        display: none;
      }
    }
  }
}

@include rw-max (960) {
    .dashboard-table::after{
      height: 69.8px !important;
      right: -0.15px;
    }
  }
.el-table {
  padding-right: 2px;
  position: relative;
  &::before,
  &::after {
    display: none;
  }

  th.gutter {
    display: none;
  }

  .cell {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 30px !important;
    @include rw-max(700) {
      padding: 8px 12px !important;
    }
  }
  th {
    height: 54px !important;
    background-color: transparent !important;
    border: none !important;

    &:first-child {
      .cell {
        border-top-left-radius: 20px;
        border-left: 1px solid #dee2e6;
      }
    }

    &:nth-last-child(2) {
      .cell {
        border-right: 1px solid #dee2e6;
        border-top-right-radius: 20px;
      }
    }

    .cell {
      height: inherit;
      font-size: 16px;
      font-weight: 500;
      background-color: transparent !important;
      border: 1px solid #dee2e6;
      border-left: none;
    }
  }

  tr {
    td {
      border-bottom: none;
      height: 54px !important;

      &:last-child {
        .cell {
          border-right: 1px solid #dee2e6;
        }
      }

      &:first-child {
        .cell {
          border-left: 1px solid #dee2e6;
        }
      }

      .cell {
        border-right: 1px solid #dee2e6;
        font-size: 14px;
        height: inherit;
        width: 100%;
      }
    }
  }

  // last summary item
  .el-table__body {
    tr {
      &:last-child {
        td {
          .cell {
            border-top: 1px solid #dee2e6;
          }

          &:not(:where(:first-child, :nth-last-child(2), :last-child)) {
            .cell {
              border-right: none;
            }
          }

          &:first-child {
            .cell {
              border-bottom: 1px solid #dee2e6;
              border-bottom-left-radius: 20px;
            }
          }

          @include rw-max(700) {
            &:nth-child(2) {
              .cell {
                background-color: #f0f1f2;
                color: #f37222;
                font-size: 18px;
                border-right: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                border-bottom-right-radius: 20px;
              }
            }
            &:nth-last-child(2) {
              .cell {
                border-right: none;
              }
            }
            &:last-child {
              background: transparent;
              .cell {
                background-color: transparent !important;
                border-right: none !important;
                border-bottom: none !important;
              }
            }
          }

          &:last-child {
            .cell {
              background-color: #f0f1f2;
              color: #f37222;
              font-size: 18px;
              border-bottom: 1px solid #dee2e6;
              border-bottom-right-radius: 20px;
            }
          }
        }
      }
    }
  }

  .icons-badge {
    padding: 0;
    margin-left: 0;
    font-size: 15px;
    min-width: 176px;
    span {
      flex-grow: 1;
      white-space: initial;
      text-align: left;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span[role=button] {
    width: calc(100% + 3px) !important;
    display: flex;
    justify-content: space-between;
  }

  &__body-wrapper {
    padding-bottom: 16px;
  }
}

.el-table__fixed,
.el-table__fixed-right {
  bottom: 70px !important;
  -webkit-overflow-scrolling: touch;
  overflow-x: clip !important;
}

.el-table__fixed {
  overflow: hidden;
  box-shadow: none !important;
  //border-right: 1px solid #dee2e6;
  @include rw-max(700) {
    height: calc(100% - 16px);
  }
}

.el-table__fixed-right {
  border-bottom-left-radius: 20px !important;
}

.el-table__body-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll !important;
  overflow-x: overlay !important;
  scrollbar-width: thin;
  scrollbar-color: rgba(128, 129, 145, 0.5) #fff;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: #f0f1f2;
    border-radius: 100px;
  }
  // table {
  //   width: unset !important;
  // }
}

// .el-table__header-wrapper {
//   table {
//     width: unset !important;
//   }
// }

.el-table__header,
.el-table__body,
.el-table__footer {
  min-width: 100%;
}

.cell {
  height: 100%;
  width: 100%;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  @include system-fontstack;
}

/*SAFARI*/

.el-table-Safari {
  position: -webkit-sticky !important;
  .el-table__body-wrapper {
    z-index: 1;
  }
  .el-table__fixed,
  .el-table__fixed-right {
    z-index: 2;
  }
  .el-table__fixed-right {
    border-bottom-left-radius: 20px;
  }
}

.el-table__fixed-right::before, .el-table__fixed::before {
  @include rw-max(700) {
    display: none;
  }
}
