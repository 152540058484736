@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../helpers/index";

.dashboard-content-wrapper {
  font-family: "Cera Pro", sans-serif;
  padding: 0 40px 29px 40px !important;
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  @include media-breakpoint-down(700px) {
    padding: 0 5px 30px !important;
  }
  @include media-breakpoint-up("sm") {
    padding: 0px 20px 20px 20px !important;
  }
  @include media-breakpoint-up("lg") {
    padding: 0 40px !important;
    .dashboard-pie-chart-name {
      padding-top: 20px;
    }
  }
}

.total-balance-wrapper {
  margin-bottom: 40px;
  @include media-breakpoint-down("sm") {
    margin-bottom: 20px;
  }
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.total-balance {
    @include cera;
    font-size: 32px;
    line-height: 1.25;
    color: $tango;

    @include rw-max(700) {
        font-size: 28px;
    }
}

.welcome-wrapper {
    margin-bottom: 43px;

    @include rw-max(700) {
        margin: 21px 0;
    }
}

.dashboard-loading {
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
}

.dashboard-content {
  border-radius: 20px;
  @media (max-width: 560px) {
    padding: 0;
  }
  @media (max-width: 420px) {
    .dashboard-pie-chart-name-assets,
    .dashboard-pie-chart-name-platform {
      font-size: 18px;
    }
  }
  @media (max-width: 360px) {
    .dashboard-pie-chart-name-assets,
    .dashboard-pie-chart-name-platform {
      font-size: 15px;
    }
  }
}

.row {
  @include media-breakpoint-up(1170px) {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0 !important;
    justify-content: space-between;
  }
}

.chart-assets {
  @media (max-width: 1170px) {
    margin-bottom: 25px;
  }
}

.chart-assets,
.chart-platform {
  border: 1px solid #fff;
  background: #fff;
  border-radius: 20px;
  margin-top: 0 !important;
}

.col-xl-6 {
  @media (max-width: 1170px) {
    width: 100% !important;
  }
  width: 49% !important;
}

.col-xl-12 {
  width: 100%;
}

.currency-select {
  color: black !important;
  svg {
    background: transparent;
  }
  .MuiNativeSelect-icon.Mui-disabled {
    color: black !important;
  }
}

.table-row {
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
}

.asset-wrapper {
  font-size: 16px;
  color: $tuna;
  align-items: center;
  vertical-align: center;
  > div {
    margin-left: 16px;
  }
  img {
    border: 1px solid rgba(128, 129, 145, 0.5);
    border-radius: 25px;
    background: white;
  }
}

.network-type {
  margin-bottom: 20px;
  color: $tuna;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 400;
}

.currency-image {
  height: 32px;
  width: 32px;
}

.table-wrapper {
  border: 1px solid $cararra;
  border-radius: 20px;
  margin-bottom: 24px;
}

.table-content {
  padding: 5px 0;
  > .row > span {
    border-left: 1px solid black;
  }
  .row {
    padding: 5px 0;
  }
}

.paired-image-wrapper {
  margin-right: 10px;
  .overlapping-image {
    position: relative;
    right: 16px;
    z-index: 2;
  }
  .paired-image:first-child {
    position: relative;
    z-index: 3;
  }
  .badge-image-wrapper {
    position: absolute;
    z-index: 4;
    img {
      background: white;
      position: relative;
      left: 38px;
      bottom: 40px;
    }
    .no-second-icon {
      left: 20px;
    }
  }
}

.paired-image-wrapper-right {
  margin-right: 0!important;
  margin-left: 10px;
}


.badge-image {
  width: 18px;
  height: 18px;
}

.paired-image {
  width: 32px;
  height: 32px;
}

.paired-image-sml {
  width: 24px;
  height: 24px;
}

.deposit-image-wrapper {
  align-items: center;
  vertical-align: center;
  img {
    background: white;
    border: 1px solid rgba(128, 129, 145, 0.5);
    border-radius: 25px;
  }
  .badge-image-wrapper {
    position: absolute;
    img {
      position: relative;
      top: -39px;
      left: 21px;
    }
  }
}

.mw-80 {
  min-width: 80px;
}

.balance-usd {
  width: 100%;
  text-align: right;
}

.no-break {
  white-space: nowrap;
}

.ordering {
  &.orderingActive {
    color: #333;
    &.orderingAsc {
      // some stypes
    }
    &.orderingDesc {
      // some stypes
    }
  }
}

.icons-badge {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
  word-wrap: normal;
  white-space: pre;
}

.end-row-icons-badge {
  padding: 8px 12px !important;
  border-bottom: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-bottom-left-radius: 20px;
  width: 100%;
  position: absolute;
  left: -1px;
  z-index: 20;
  overflow: visible;
  img {
    border: none;
  }
}
